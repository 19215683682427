import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { PageType } from '@constants/pageType.constants';
import { responseType } from '@helpers/guarantee.helper';

import RegisterResponseTemplate from '../register-response-template/RegisterResponseTemplate.component';

const SendingProblem = () => {
  const intl = useIntl();

  return (
    <RegisterResponseTemplate
      header={intl.formatMessage({ id: 'sendingProblem_header' })}
      descriptionPart1={intl.formatMessage({ id: 'sendingProblem_description' })}
      buttonText={intl.formatMessage({ id: 'sendingProblem__responseButton' })}
      result={responseType.Fail}
      buttonTargetPage={PageType.CONTACT}
    />
  );
};

export default SendingProblem;
